.dropdown {
  float: left;
  overflow: hidden;
  margin-top: 1.9vh;
  margin-left: 1.9vw;
  margin-right: 2.1vw;
  font-weight: normal;
  font-size: revert;
  text-decoration: none;
}

/* Dropdown button */
.dropdown .dropbtn {
  font-size: larger;
  border: none;
  outline: none;
  color: white;
  background-color: inherit;
  font-family: inherit;
  /* Important for vertical align on mobile phones */
  margin: 0;
  /* Important for vertical align on mobile phones */

  @media (max-width: 550px) {
    font-size: 1.3rem;
  }
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  z-index: 1;
  margin-left: -10px;
  margin-top: initial;
}

/* Links inside the dropdown */
.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: solid;
  display: block;
  text-align: left;

  @media (max-width: 550px) {
    font-size: 1.3rem;
  }
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
  background-color: lightblue;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}